import Choices from 'choices.js';

/**
 * Choices.js -plugin setup & configuration
 */

function initChoices(selectEl) {
  const choicesInstance = new Choices(selectEl, {
    //searchEnabled: selectEl.dataset.choiceSort !== 'false',
    searchEnabled: false,
    removeItemButton: selectEl.dataset.removeItemButton === 'true' || selectEl.multiple,
    shouldSort: false,
    loadingText: 'Loading...',
    noResultsText: 'No results',
    noChoicesText: 'No values',
    itemSelectText: 'Select by clicking',
    placeholder: true,
    placeholderValue: "Select",
    labelId: 'select--filter',
    addItemText: value => `Press Enter to add <b>"${value}"</b>`,
    maxItemText: maxItemCount => `Maximum number of values is ${maxItemCount}`,
    // classNames: {
    //   flippedState: selectEl.dataset.choiceFlip === 'false' ? 'no-flipped-state' : 'is-flipped',
    // },
  });
  window.choicesInstances.set(selectEl, choicesInstance);
  // selectEl.choicesInstance = choices;
  return choicesInstance;
}

function init() {
  let selectEls = document.querySelectorAll('.js-choice, .js-c-styled-select > select, .js-c-styled-select .gfield_select') || [];

  // Ignore AlpineJS selects. They should be initialized by Alpine in x-init.
  selectEls = [...selectEls].filter(el => !el.getAttribute('x-model'));

  // Create a new global object where to save references to instances, to be used in other components.
  window.choicesInstances = new WeakMap();

  // Initilize elements
  selectEls.forEach((selectEl) => {
    initChoices(selectEl);
  });

  // Make initialization available for AlpineJS
  window.initChoices = initChoices;
}

export default {
  init,
};
