//import tinysort from 'tinysort';
import { filter, throttle } from 'lodash-es';

let filterBlockEl;
let formEl;
let itemEls;
let noResultsMessageEl;
//let maxPerPage = 2;
//let resultCount;

function init() {

  const doFilterThrottled = throttle(doFilter, 500);
  filterBlockEl = document.querySelector('.grid-filters');

  const downloadLink = document.querySelector('.download-file');

  if (downloadLink) {
    downloadLink.addEventListener('change', (event) => { event.preventDefault() });
  }

  if (!(filterBlockEl)) {
    return;
  }

  formEl = document.querySelector('.grid-filters form');
  const formControlSelectEls = formEl ? formEl.querySelectorAll('select') : [];
  const hiringCheckbox = document.querySelector('.grid-filters form input#filter-hiring');

  noResultsMessageEl = document.querySelector('.b-filter-grid .item__no-results');
  itemEls = document.querySelectorAll('.b-filter-grid .item');
  //resultCount = itemEls.length;

  [...formControlSelectEls].forEach((el) => {
    el.addEventListener('change', (event) => {
      if (el.classList.contains('js-choice')) {
        doFilterThrottled();
      } else if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });

  if (hiringCheckbox) {
    hiringCheckbox.addEventListener('change', function() {
      doFilterThrottled();
    });
  }

  /**
   * Initial state
   */

  let initialStateParams;

  if (window.history && window.history.state) {
    initialStateParams = window.history.state.stateParams;
  } else if (window.location.search) {
    // Get all form field names,
    const fieldNames = [...(formEl && formEl.elements)]
      .map(fieldEl => fieldEl.name)
      .filter(v => v);
    const fieldNamesUnique = [...new Set(fieldNames)];

    // Compare search param keys to form field names
    const initialState = new URLSearchParams(window.location.search);
    const paramNames = [...initialState.keys()];
    const intersectingNames = paramNames.filter(x => fieldNamesUnique.includes(x));

    // Only set state from parameters if there is any match to actual field names.
    // To avoid setting state just because there is *any* param, like &seravo_shadow or some tracking params.
    if (intersectingNames.length) {
      initialStateParams = window.location.search;
    }
  }

  applyStateToForm(initialStateParams);
  doFilter(false);

  /**
   * Listen to history change (back/forward)
   */
   window.addEventListener('popstate', (event) => {
    applyStateToForm(event.state ? event.state.stateParams : '');
    doFilter(false);
    doSort(false);
  });

}

/**
 * Handle filtering, do changed to DOM
 */
 function doFilter(shouldUpdateURL = true) {
  const formState = getStateFromForm();
  const searchCriteria = formatStateToSearchCriteria(formState);

  if (shouldUpdateURL) {
    updateURLstate();
  }

  // Prepare for company list
  const downloadLink = document.querySelector('.download-file');
  //downloadLink.addEventListener('change', (event) => { event.preventDefault() });
  const urlString = window.location.href;
  let url = new URL(urlString);
  let catParams = url.searchParams;
  let cats = [];

  [...catParams].forEach( el => cats.push(el[1]) );

  let timestamp = Math.floor(Date.now()/1000);

  if (cats.length === 0) {
    cats = -1;
  }

  if (downloadLink) {
    downloadLink.href = `${wpApiSettings.root}v1/download_company_list/cats/${cats}`;
  }

  // Hide all items initially
  [...itemEls].forEach(el => el.classList.add('hidden'));

  // Filter matching items
  const activeItems = [...itemEls].filter((el) => {
    return matchItemToSearchCriteria(el, searchCriteria);
  });

  // Show matching items
  activeItems.forEach(el => el.classList.remove('hidden'));

  //https://stackoverflow.com/questions/33424138/how-to-remove-a-div-with-fade-out-effect-in-javascript

  // Toggle "No results" message
  noResultsMessageEl.classList.toggle('hidden', activeItems.length);
}


/**
 * Get FormData object from filter form.
 */
function getStateFromForm() {
  const formState = new FormData(formEl || undefined);
  return formState;
}


/**
 * Get filter criteria values from state, format them and give default values.
 * Default values basically allow all items to match, so that if no filter-selections are made,
 * all items are shown.
 */
 function formatStateToSearchCriteria(state) {
  const filterCategory = state.getAll('category[]').map(value => Number(value));
  const filterHiring = state.get('hiring');

  return {
    filterCategory,
    filterHiring
  };
}


/**
 * Apply state to DOM. Update input values etc.
 */
function applyStateToForm(stateParams) {
  const state = new URLSearchParams(stateParams);
  const formControlEls = formEl ? formEl.querySelectorAll('input, select') : [];

  // Loop through all form control elements and update their value from state.
  [...formControlEls].forEach((el) => {
    if (el.tagName === 'INPUT' && ['text', 'number', 'hidden'].includes(el.type)) {
      el.value = state.get(el.name);
    } else if (el.tagName === 'INPUT' && el.type === 'radio') {
      el.checked = state.get(el.name) === el.value;
    } else if (el.tagName === 'INPUT' && el.type === 'checkbox') {
      el.checked = state.getAll(el.name).includes(el.value);
    } else if (el.tagName === 'SELECT') {
      const options = state.getAll(el.name);
      const choicesInstance = window.choicesInstances.get(el);
      if (choicesInstance) {
        // Select is using choices.js
        if (choicesInstance._isSelectMultipleElement) {
          // Removing values from multiselect doesn't work with just array of options.
          // Have to clear out all values and apply new ones.
          // choicesInstance.removeHighlightedItems(); // Why this doesn't work?
          const selectedValues = choicesInstance.getValue(true);
          selectedValues.forEach((value) => {
            choicesInstance.removeActiveItemsByValue(value);
          });
        }
        choicesInstance.setChoiceByValue(options);
      } else {
        [...el.children].forEach((optionEl) => {
          optionEl.selected = options.includes(optionEl.value);
        });
      }
    }
  });

}

/**
 * Compare item to search criterias.
 */
function matchItemToSearchCriteria(el, searchCriteria) {
  const {
    filterCategory,
    filterHiring,
  } = searchCriteria;

  //const cardEl = el.firstElementChild;
  const cardEl = el;

  const itemCategories = cardEl.dataset.categories
    ? cardEl.dataset.categories.split(';').map(val => Number(val))
    : [];

  /*
  const itemHiring = cardEl.dataset.hiring;
  let matchHiring;

  if (filterHiring != 'on') {
    matchHiring = true;
  }
  else {
    matchHiring = itemHiring === filterHiring ? true : false;
  }
  */

  // itemCategory: Test filter array includes item itemCategory
  const matchCategory = !filterCategory.length || filterCategory.filter(filterValue => itemCategories.includes(filterValue)).length;

  //return matchCategory && matchHiring;
  return matchCategory;
}


/**
 * Update state to URL using History PushState.
 */
 function updateURLstate() {
  const formState = getStateFromForm();
  const stateParams = new URLSearchParams(formState).toString();

  window.history.pushState({ stateParams }, '', stateParams ? `?${stateParams}` : `${window.location.origin}${window.location.pathname}`);

}


export default {
  init,
};
